<template>
    <div class="playlist__success">
        <div class="deckslide__header">
            <div class="deckslide__header__left">
                <img @click="returnToOverview" src="@/assets/ic_nav_back.svg" alt="">
            </div>
            <div class="deckslide__header__center">
                Workout
            </div>
            <div class="deckslide__header__right">
                <img @click="sharePlaylist" src="@/assets/ic_nav_share.svg" alt="">
            </div>
        </div>

        <!-- HEADING -->
        <img :src="coachImage" alt="" class="playlist__success__avatar"/>
        <h1>{{ motivationTitle }}</h1>
        <p>{{ motivationText }}</p>

        <!-- LIST -->
        <div class="list">

            <div v-show="energyExpended > 0" class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Aktivitätskalorien
                    </div>
                    <img src="@/assets/ic_16_info.svg" alt="Info" @click="openInfoModal"/>
                    <div v-if="dataIncludeWarmup" class="list__item__value">
                        {{ energyExpended }} kcal (inkl. Warmup)
                    </div>
                    <div v-else class="list__item__value">
                        {{ energyExpended }} kcal
                    </div>
                </div>
            </div>

            <div class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Dauer
                    </div>
                    <div v-if="dataIncludeWarmup" class="list__item__value">
                        {{ realDuration }} Min (inkl. Warmup)
                    </div>
                    <div v-else class="list__item__value">
                        {{ realDuration }} Min
                    </div>
                </div>
            </div>

            <label v-if="!workoutSource.uid" class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Zu "Meine Workouts" hinzufügen?
                    </div>
                </div>
                <div class="toggle">
                    <input :disabled="workoutSource.uid" v-model="savePlaylist" type="checkbox">
                    <span class="toggle__button"></span>
                </div>
            </label>

            <!--    addToFitnessPlan must always be true for the data to be available in the statistics. Therefore the toggle button is no longer needed -->
<!--            <label v-if="!workoutSource.fitnessPlanEvent" class="list__item">
                <div class="list__item__content">
                    <div class="list__item__label">
                        Zu "Mein Trainingsplan" hinzufügen?
                    </div>
                </div>
                <div class="toggle">
                    <input v-model="addToFitnessPlan" type="checkbox">
                    <span class="toggle__button"></span>
                </div>
            </label>-->

        </div>

        <VModal name="energyInfo" class="centered" width="100%" height="auto" :scrollable="true">
            <div class="vm-header">
                <div class="vm-header-left">
                </div>
                <div class="vm-header-center">
                    Hinweis
                </div>
                <div class="vm-header-right">
                    <img src="@/assets/ic_nav_close.svg" alt="" @click="closeInfoModal">
                </div>
            </div>
            <div class="vm-content">
                <p style="margin-top: 0;">Bei den Aktivitätskalorien handelt es sich um einen Schätzwert, der auf
                    statistischen Werten sowie deinen Angaben zu Körpergröße, Gewicht und Alter basiert. Für eine
                    exaktere Ermittlung der Aktivitätskalorien empfehlen wir die Verwendung eines Fitness-Trackers.</p>
                <v-btn type="button" class="button button--primary button--small" @click="closeInfoModal">Alles klar
                </v-btn>
            </div>
        </VModal>

        <!-- BUTTON -->
        <div class="button-group">
            <v-btn v-if="!this.originWorkoutId" @click="startImportTrackingData"
                   class="button button--outline button--small button--icon">
                <img src="@/assets/ic_16_share.svg" alt="">Fitnessdaten importieren
            </v-btn>
            <v-btn @click="returnToOverview" class="button button--primary">{{ getButtonText() }}</v-btn>
        </div>

    </div>
</template>

<script>
import {analytics} from '@/firebase/index.js';
import {toIsoString} from '@/sportlerplus-helper';
import {sharePage, generateGuid} from '@/sportlerplus-helper';

const motivation_high_intensity = [
    ["Wow, starke Leistung!",
        "Das war ein anstrengendes und intensives Training! Aber du hast es durchgezogen und das zeichnet dich aus."],
    ["Du bist eine Maschine!",
        "Dieses harte Workout muss dir erstmal einer nachmachen. Du bist ein echter SportlerPlus!"],
    ["Einfach Wahnsinn!",
        "Ob dein Körper nach diesem Workout überhaupt noch abkühlt? Denn das war wahnsinnig gut!"],
    ["Klasse, du gehst ein Tempo wie unsere Pros!",
        "Mit so einem Workout stehst du unseren Coaches in nichts nach! Du kannst stolz auf dich sein!"],
    ["Deine Performance ist überragend!",
        "Die Dusche hast du dir redlich verdient! Aber denk dran, Gewinner ruhen sich nicht auf ihrem Erfolg aus!"]
]

const motivation_medium_intensity = [
    ["Whoop whoop!",
        "Du bist richtig gut in Fahrt. Weiter so!"],
    ["You did it!",
        "Tag für Tag und Training für Training kommst du deinem Ziel ein Stück näher. Du bist kurz vor Pro-Level."],
    ["Hammer Training!",
        "Du kannst alles schaffen! Mal wieder hast du gezeigt, wie man den inneren Schweinehund besiegt!"],
    ["Wow, du bist gut!",
        "Dein heutiges Maximum ist schon bald dein Warm-up! Du musst nur dranbleiben. Mach weiter so!"],
    ["No pain, no gain!",
        "Bei diesem Training hast du dieses bekannte Zitat verkörpert wie kein Zweiter! Nur so kommt man zu seinen Zielen! Richtig gut!"]
]

const motivation_low_intensity = [
    ["Glückwunsch!",
        "Deine ersten Steps auf dem Weg zum SportlerPlus hast du gut gemeistert. Jetzt am Ball bleiben!"],
    ["Training done!",
        "Du wirst immer besser und besser. Augen aufs Ziel und jeden Tag Vollgas geben. Dann kommen auch die Erfolge schneller."],
    ["Stark!",
        "Stabile Performance. Sei stets weiter motiviert und steigere dich nach und nach. Keep going!"],
    ["Gut gemacht!",
        "Du hast eine gute Etappe auf dem Weg zum SportlerPlus erfolgreich gemeistert. Gut gemacht!"],
    ["Sauber, weiter so!",
        "Dieses leichte Workout hast du souverän hinter dir gelassen. Auf geht`s zum nächsten Level."]
]

export default {
    name: 'PlaylistSuccess',
    data() {
        return {
            workoutSource: {},
            savePlaylist: false,
            height: null,
            weight: null,
            gender: null,
            userInformation: null,
            birthday: null,
            age: null,
            ree: 0,
            energyExpended: 0,
            realDuration: 0,
            motivationalPhrases: motivation_low_intensity,
            mpIndex: 0,
            coachImage: null,
            fitnessPlan: null,
            addToFitnessPlan: true,
            originWorkoutId: null,
            importTrackingData: false,
            newEvent: null,
            existingStartedActivity: null,
            dataIncludeWarmup: false,
        }
    },
    methods: {
        init() {
            this.workoutSource = this.$store.getters.getWorkoutSource;

            this.originWorkoutId = window.localStorage.getItem('originWorkoutId');

            if (window.localStorage.getItem('energyExpended') && window.localStorage.getItem('realDuration')) {
                this.energyExpended = parseInt(window.localStorage.getItem('energyExpended'));
                this.realDuration = parseInt(window.localStorage.getItem('realDuration'));
                this.dataIncludeWarmup = true;
                window.localStorage.removeItem('energyExpended');
                window.localStorage.removeItem('realDuration');
            }

            this.mpIndex = Math.floor(Math.random() * this.motivationalPhrases.length);
            if (this.workoutSource && this.workoutSource.coach) {
                this.coachImage = this.getCoachImage(this.workoutSource.coach.id);
            } else {
                this.coachImage = this.randomCoachImage();
            }

            this.$store.dispatch('fetchUserInformation').then((res) => {
                if (res.length == 0) {
                    this.userInformation = this.$store.getters.getOnboardingData;
                    this.birthday = this.userInformation.birthday;
                } else {
                    this.userInformation = res[0];
                    if (this.userInformation.birthday) {
                        this.birthday = new Date(0);
                        this.birthday.setUTCSeconds(this.userInformation.birthday.seconds);
                    }
                }
                this.height = this.userInformation.height || 175;
                this.weight = this.userInformation.weight || 75;
                if (this.userInformation.gender) {
                    this.gender = parseInt(this.userInformation.gender);
                } else {
                    this.gender = 2;
                }

                let today = new Date();
                if (this.birthday) {
                    this.age = Math.floor((today - this.birthday) / (1000 * 365 * 24 * 60 * 60));
                } else {
                    this.age = 35; // assumed default value
                }

                this.ree = this.restingEnergyExpenditure(this.age, this.height, this.weight, this.gender);
                console.log("REE = " + this.ree);
                let energyExpended = 0;
                let realDuration = 0;
                if (this.ree > 0) {
                    this.workoutSource.completedExercises.forEach((it) => {
                        realDuration = realDuration + it[0];
                        energyExpended = energyExpended + (this.ree / 86400 * it[0] * it[1])
                    });
                    console.log("energyExpended = " + energyExpended);
                    this.energyExpended += Math.round(energyExpended);
                    this.realDuration += Math.round(realDuration / 60);
                } else {
                    this.realDuration = this.workoutSource.workoutVideo.duration;
                }

                if (this.workoutSource.workoutVideo) {
                    analytics.logEvent('workout_finished', {
                        workout_id: this.workoutSource.workoutVideo.id,
                    });

                    let difficulty = this.workoutSource.workoutVideo.difficulty;
                    if (difficulty == 3) {
                        this.motivationalPhrases = motivation_high_intensity;
                    } else if (difficulty == 2) {
                        this.motivationalPhrases = motivation_medium_intensity;
                    }
                } else {
                    if (this.workoutSource.activity.uid) {
                        analytics.logEvent('user_playlist_finished', {user_playlist_id: this.workoutSource.activity.uid});
                    } else if (this.workoutSource.activity.meta && this.workoutSource.activity.meta.type == "exercise.PlaylistPage") {
                        analytics.logEvent('playlist_finished', {playlist_id: this.workoutSource.activity.id});
                    } else {
                        analytics.logEvent('category_playlist_finished', {category_id: this.workoutSource.activity.id});
                    }
                    if (this.realDuration > 40) {
                        this.motivationalPhrases = motivation_high_intensity;
                    } else if (this.realDuration > 15) {
                        this.motivationalPhrases = motivation_medium_intensity;
                    }
                }

                if (this.workoutSource.fitnessPlanEvent) {
                    this.completeFitnessPlanEvent(this.workoutSource.fitnessPlanEvent, this.energyExpended, this.realDuration);
                    this.addToFitnessPlan = false;
                }
            });
        },
        completeFitnessPlanEvent(eventInfo, energyExpended, realDuration) {
            this.$store.dispatch('fetchFitnessPlan').then((res) => {
                if (res.length != 0) {
                    this.fitnessPlan = res[0];
                    if (this.fitnessPlan.startedActivities) {
                        this.existingStartedActivity = this.fitnessPlan.startedActivities.find(el => el.guid == eventInfo.guid && el.activityDate == eventInfo.activityDate && el.activityTime == eventInfo.activityTime);
                    } else {
                        this.fitnessPlan.startedActivities = [];
                    }

                    if (this.existingStartedActivity) {
                        this.existingStartedActivity.activityState = "finished";
                        this.existingStartedActivity.realDuration = realDuration;
                        this.existingStartedActivity.energyExpended = energyExpended;
                        this.existingStartedActivity._realStartDate = eventInfo._realStartDate;
                        this.updateFitnessPlan();
                    }
                }
            });
        },
        async addNewFitnessPlanEvent(eventInfo, energyExpended, realDuration) {
            await this.$store.dispatch('fetchFitnessPlan').then((res) => {
                if (res.length != 0) {
                    this.fitnessPlan = res[0];
                } else {
                    this.fitnessPlan = {"items": []}
                }
                let d = new Date(this.workoutSource.startDate);
                let dateComponents = toIsoString(d).split("T");

                this.newEvent = {
                    "activity": eventInfo, activityState: "finished",
                    "realDuration": realDuration, "energyExpended": energyExpended,
                    "startDate": d,
                    "_realStartDate": d,
                    "guid": generateGuid(),
                    "activityDate": dateComponents[0],
                    "activityTime": dateComponents[1].substring(0, 5)
                };

                this.fitnessPlan.items.push(this.newEvent);
                if (!this.fitnessPlan.startedActivities) {
                    this.fitnessPlan.startedActivities = [];
                }
                let sa = this.fitnessPlan.startedActivities;
                sa.push(this.newEvent);
                this.updateFitnessPlan();
            });
        },
        updateFitnessPlan() {
            this.$store.dispatch('saveFitnessPlan', this.fitnessPlan);
        },
        restingEnergyExpenditure(age, height, weight, gender) {
            if (gender == null || age == null || height == null || weight == null) {
                return 0;
            }
            let ree = 0;
            if (gender == 0) {
                ree = 66.5 + (13.8 * weight) + (5.0 * height) - (6.8 * age);
            } else if (gender == 1) {
                ree = 655 + (9.6 * weight) + (1.8 * height) - (4.7 * age);
            } else if (gender == 2) {
                ree = (this.restingEnergyExpenditure(age, height, weight, 0) + this.restingEnergyExpenditure(age, height, weight, 1)) / 2;
            }
            return ree;
        },
        returnToOverview() {
            if (this.addToFitnessPlan && !window.localStorage.getItem('originWorkoutId')) {
                this.addNewFitnessPlanEvent(this.workoutSource.workoutVideo || this.workoutSource.activity, this.energyExpended, this.realDuration);
            }
            if (this.savePlaylist) {
                this.handleSavePlaylist();
            } else {
                this.handleNonSavePlaylist();
            }
        },
        handleSavePlaylist() {
            let dateString = new Date().toLocaleDateString();
            this.workoutSource['name'] = "Workout vom " + dateString;
            // TODO: check whether to delete certain fields
            this.workoutSource.items = [...this.workoutSource.generatedPlaylist];
            this.workoutSource.shuffle = false;
            delete this.workoutSource["generatedPlaylist"];
            delete this.workoutSource["completedExercises"];
            this.$store.dispatch('saveUserPlaylist', this.workoutSource)
                .then(() => this.$router.push("/playlists"))
                .catch(() => alert("Fehler beim Speichern des Workouts."));
        },
        handleNonSavePlaylist() {
            if (this.importTrackingData) {
                this.handleImportTrackingData();
            } else if (this.originWorkoutId) {
                this.handlePreviousWarmup();
            } else if (this.workoutSource.fitnessPlanEvent) {
                this.$router.push("/fitnessplan");
            } else {
                this.$router.push("/trainieren");
            }
        },
        handleImportTrackingData() {
            let guid = window.localStorage.getItem('guid');
            let activityDate = window.localStorage.getItem('activityDate');
            let activityTime = window.localStorage.getItem('activityTime');
            let activity = this.existingStartedActivity || this.newEvent;
            if (!guid) {
                guid = activity.guid;
                activityDate = activity.activityDate;
                activityTime = activity.activityTime;
            }
            this.$router.push(`/fitnessplan/detailsActivity?guid=${guid}&activityDate=${activityDate}&activityTime=${activityTime}&importData=true`);
            window.localStorage.removeItem('guid');
            window.localStorage.removeItem('activityDate');
            window.localStorage.removeItem('activityTime');
        },
        handlePreviousWarmup() {

            let originWorkoutId = window.localStorage.getItem('originWorkoutId');
            window.localStorage.removeItem('originWorkoutId');
            if (window.localStorage.getItem('guid')) {
                let guid = window.localStorage.getItem('guid');
                let activityDate = window.localStorage.getItem('activityDate');
                let activityTime = window.localStorage.getItem('activityTime');
                window.localStorage.setItem('energyExpended', this.energyExpended);
                window.localStorage.setItem('realDuration', this.realDuration);
                this.$router.push(`/workouts/${originWorkoutId}?back=true&guid=${guid}&activityDate=${activityDate}&activityTime=${activityTime}`);
            }
            else {
                window.localStorage.setItem('energyExpended', this.energyExpended);
                window.localStorage.setItem('realDuration', this.realDuration);
                this.$router.push(`/workouts/${originWorkoutId}?back=true`);
            }
        },
        openInfoModal() {
            this.$modal.show('energyInfo');
        },
        closeInfoModal() {
            this.$modal.hide('energyInfo');
        },
        randomCoachImage() {
            let coaches = this.$store.getters.getCoaches;
            let coach = coaches[Math.floor(Math.random() * coaches.length)];
            return 'https://app.sportlerplus.com/scaled/200/' + coach.avatar_image_url;
        },
        getCoachImage(coachId) {
            let coaches = this.$store.getters.getCoaches;
            let coach = coaches.find(c => c.id == coachId);
            return 'https://app.sportlerplus.com/scaled/200/' + coach.avatar_image_url;
        },
        sharePlaylist() {
            var shareText;
            if (this.workoutSource.workoutVideo) {
                shareText = "Hey, ich habe gerade dieses Workout in der SportlerPlus-App absolviert: „{0}“.\n\nWar echt klasse und ist vielleicht auch was für dich! 💪🚀".format(this.workoutSource.workoutVideo.title);
            } else {
                if (this.workoutSource.activity.uid) {
                    shareText = "Hey, ich habe gerade ein selbst erstelltes Workout in der SportlerPlus-App absolviert: „{0}“.\n\nIst vielleicht auch was für dich! 💪🚀".format(this.workoutSource.name);
                } else if (this.workoutSource.activity.meta && this.workoutSource.activity.meta.type == "exercise.PlaylistPage") {
                    shareText = "Hey, ich habe gerade dieses Workout in der SportlerPlus-App absolviert: „{0}“.\n\nWar echt klasse und ist vielleicht auch was für dich! 💪🚀".format(this.workoutSource.activity.title);
                } else {
                    shareText = "Hey, ich habe gerade dieses Workout in der Kategorie „{0}“ in der SportlerPlus-App absolviert.\n\nWar echt klasse und ist vielleicht auch was für dich! 💪🚀".format(this.workoutSource.activity.name);
                }
            }
            sharePage(this.workoutSource.share_url, null, shareText, null);
        },
        startImportTrackingData() {
            this.importTrackingData = true;
            this.returnToOverview();
        },
        getButtonText() {
            if (this.originWorkoutId) {
                return "Zum Workout";
            } else {
                return "Workout beenden";
            }
        }
        /*
        shareSuccess() {
            let productLogo = require("@/assets/logo.svg");
            let postItems = {
                "productLogo": productLogo,
                "coachImage": this.coachImage,
                "motivation": this.motivationalPhrases[this.mpIndex][0],
                "energyExpended": this.energyExpended,
                "duration": this.realDuration,
                "origin": window.location.origin,
                "title": this.screenTitle,
            };

            var formData = new FormData();

            for ( var key in postItems ) {
                formData.append(key, postItems[key]);
            }

            axios.post('https://apphelper.sportlerplus.com/imagegen/shareSuccess',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then((response) => {
                    let imageUrl = response.data.url;
                    shareImage(imageUrl);
                })
        }*/
    },
    mounted() {
        this.init();
    },
    computed: {
        motivationTitle() {
            return this.motivationalPhrases[this.mpIndex][0];
        },
        motivationText() {
            return this.motivationalPhrases[this.mpIndex][1];
        }
    }
}
</script>

<style lang="scss">
.playlist__success {
  display: flex;
  flex-direction: column;
  position: relative;
  background: $colorWhite;
  z-index: 1;
  flex: 1;
  padding: 0 20px 20px;

  &__avatar {
    width: 200px;
    height: 200px;
    margin: 0px auto 14px;
  }

  h1, p {
    color: $colorBlack;
    text-align: center;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }

  p {
    margin-top: 15px;
    margin-bottom: 30px;
  }

  .list {
    width: 100%;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;

    &__item__label {
      color: rgba($colorBlack, .48);
    }

    img {
      margin-left: 4px;
    }
  }

  .button-group {
    margin: auto auto 0;
    width: 100%;
    max-width: 480px;
  }
}
</style>